import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watch } from '@nuxtjs/composition-api';

import type {
  SubscriptionPacksQuery,
  SubscriptionPacksQueryVariables,
  SubscriptionPackDetailsQuery,
  SubscriptionPackDetailsQueryVariables,
} from '@/graphql/generated';
import { subscriptionPackDetailsQuery, subscriptionPacksQuery } from '@/graphql/queries/subscriptions';

export type SubscriptionPack = NonNullable<SubscriptionPacksQuery['scenkonstOtSubscriptionPacks']>[number];

export const useSubscriptions = (subscriptionPackIds: string[]) => {
  const activeSubscriptionPack = ref<SubscriptionPack | null>(null);
  const selectedCategory = ref<string | null>(null);
  const selectedWeekday = ref<string | null>(null);

  const { result: subscriptionPacksResult, loading: subscriptionPacksLoading } = useQuery<SubscriptionPacksQuery, SubscriptionPacksQueryVariables>(
    subscriptionPacksQuery,
    () => ({ subscriptionPackIds }),
    { prefetch: false },
  );

  const { result: subscriptionPackDetails, loading: subscriptionPackDetailsLoading } =
    useQuery<SubscriptionPackDetailsQuery, SubscriptionPackDetailsQueryVariables>(
      subscriptionPackDetailsQuery,
      () => ({ subscriptionPackId: activeSubscriptionPack.value?.id }),
      () => ({ prefetch: false, enabled: !!activeSubscriptionPack.value }),
    );

  const subscriptionPackCategories = computed(() => subscriptionPackDetails.value?.scenkonstOtSubscriptionPerformanceCategories || []);
  const subscriptionPackWeekdays = computed(() => subscriptionPackDetails.value?.scenkonstOtSubscriptionWeekdays || []);
  const subscriptionPackSubscriptions = computed(() => subscriptionPackDetails.value?.scenkonstOtSubscriptions || []);

  // Reset selections when active subscription pack is nulled
  watch(activeSubscriptionPack, (value) => {
    if (!value) {
      selectedCategory.value = null;
      selectedWeekday.value = null;
    }
  });

  watch([subscriptionPackDetails, activeSubscriptionPack], () => {
    if (!selectedCategory.value)
      selectedCategory.value = subscriptionPackCategories.value[0]?.id;

    if (!selectedWeekday.value)
      selectedWeekday.value = subscriptionPackWeekdays.value[0]?.id;
  });

  const subscriptionPackSubscriptionsFiltered = computed(() =>
    subscriptionPackSubscriptions.value
      .filter(subscription =>
        subscription.weekday.toString() === selectedWeekday.value &&
        subscription.performanceCategory.id === selectedCategory.value,
      ),
  );

  return {
    subscriptionPacks: computed(() => subscriptionPacksResult.value?.scenkonstOtSubscriptionPacks || []),
    subscriptionPacksLoading,
    subscriptionPackDetailsLoading,
    subscriptionPackCategories,
    subscriptionPackWeekdays,
    subscriptionPackSubscriptions,
    subscriptionPackSubscriptionsFiltered,
    activeSubscriptionPack,
    selectedCategory,
    selectedWeekday,
  };
};
